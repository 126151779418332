import {TdrRegelAard} from "./tdr-regel-aard.enum";

export class TdrRegel {
  trajectid: number;
  plaats: string;
  tijd: number;
  passing: boolean = false;
  aard:TdrRegelAard = null;

  constructor(trajectid: number, plaats: string, tijd: number) {
    this.trajectid = trajectid;
    this.plaats = plaats;
    this.tijd = tijd;
  }

  getTijdHmm(): string {
    let minutes = this.tijd % 60;
    let hours = (this.tijd - minutes) / 60;
    let extraZero = (minutes < 10 ? "0" : "");
    return `${hours}:${extraZero}${minutes}`;
  }
}