import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import {AppComponent} from './app.component';
import { MinutesToTime } from './minutes-to-time.pipe';
import {TDRRegelComponent} from "./tdr-regel/tdr-regel.component";
import {TreinComponent} from "./trein/trein.component";
import {LocalStorageModule} from "angular-2-local-storage";
import {SortPipe} from "./sort-by.pipe";
import {SortPSubPipe} from "./sort-by-sub.pipe";
import {ClipboardModule} from "ngx-clipboard";
import {LokComponent} from "./lok/lok.component";
import {OpmerkingComponent} from "./opmerking/opmerking.component";
import { OpmerkingService } from './opmerking/opmerking.service';
import { TrajectService } from "./traject/traject.service";
import { TreinService } from './trein/trein.service';
import { LokService } from './lok/lok.service';
import { TrajectTonenComponent } from './traject-tonen/traject-tonen.component';

@NgModule({
  declarations: [
    AppComponent,
    MinutesToTime,
    TDRRegelComponent,
    TreinComponent,
    SortPipe,
    SortPSubPipe,
    LokComponent,
    OpmerkingComponent,
    TrajectTonenComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    LocalStorageModule.withConfig({
      prefix: 'tdrhelper',
      storageType: 'localStorage'
    }),
    ClipboardModule
  ],
  providers: [MinutesToTime, OpmerkingService, TrajectService, TreinService, LokService],
  bootstrap: [AppComponent]
})
export class AppModule { }
