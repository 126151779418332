import {Pipe} from "@angular/core";

@Pipe({
  name: "sortBySub",
  pure: false
})
export class SortPSubPipe {
  transform(array: Array<any>, args: string, sub: string): Array<any> {
    array.sort((a: any, b: any) => {
      let order = 0;
      if (a[args][0] && b[args][0]) {
        if (a[args][0][sub] < b[args][0][sub]) {
          order = -1;
        }
        else if (a[args][0][sub] > b[args][0][sub]) {
          order = 1;
        }
      }
      return order;
    });
    return array;
  }
}