import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import {map} from "rxjs/operators";

import { Opmerking } from './opmerking';

@Injectable()
export class OpmerkingService {

  private opmerkingenUrl = './assets/diagram.json';  // URL to web api

  constructor(
    private http: HttpClient) { }

  getOpmerkingen (): Observable<Opmerking[]> {
    return this.http.get<any>(this.opmerkingenUrl)
			.pipe(map(diagram => diagram.opmerkingen));
  }

}
