import {Component, Input, OnInit} from '@angular/core';
import {Traject} from "../traject/traject";

@Component({
  selector: 'traject-tonen',
  templateUrl: './traject-tonen.component.html',
  styleUrls: ['./traject-tonen.component.css']
})
export class TrajectTonenComponent implements OnInit {
  @Input() traject: Traject;
  @Input() trajectenTonen: number[];

  constructor() { }

  ngOnInit() {
  }

  addRemoveTraject() {
    let index = this.trajectenTonen.indexOf(this.traject.id);
    if (index > -1) {
      this.trajectenTonen.splice(index, 1);
    }
    else {
      this.trajectenTonen.push(this.traject.id);
    }
  }

}
