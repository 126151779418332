import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'opmerking',
  templateUrl: './opmerking.component.html'
})
export class OpmerkingComponent {
  @Input() opmerking: string;
  @Input() opmerkingen: string[];

  @Output()
  notify: EventEmitter<any> = new EventEmitter<any>();

  addRemoveOpmerking(opmerkingen, opmerking) {
    let index = opmerkingen.indexOf(opmerking);
    if (index > -1) {
      opmerkingen.splice(index, 1);
    }
    else {
      opmerkingen.push(opmerking);
    }
    this.notify.emit();
  }
}