import {Component, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import {TdrRegel} from "./tdr-regel.model";
import {Trein} from "../trein/trein";
import {Traject} from "../traject/traject";
import {AsPlaats} from "../traject/as-plaats";

@Component({
  selector: 'tdrRegel',
  templateUrl: './tdr-regel.component.html',
  styleUrls: ['./tdr-regel.component.css'],
})
export class TDRRegelComponent implements OnChanges {
  @Input() traject: Traject;
  @Input() plaats: AsPlaats;
  @Input() trein: Trein;
  tdrRegel: TdrRegel;

  @Output()
  notify: EventEmitter<any> = new EventEmitter<any>();

  ngOnChanges() {
    this.setTdrRegel();
  }

  setTdrRegel() {
    this.tdrRegel = null;
    for (let regel of this.trein.regels) {
      if (regel.plaats == this.plaats.naam && regel.trajectid == this.traject.id) {
        if (this.plaats.aankomstVertrek) {
          if (this.plaats.aard === regel.aard) {
            this.tdrRegel = regel;
          }
        }
        else {
          this.tdrRegel = regel;
        }
      }
    }
  }

  addRemoveTdrRegel(traject: Traject, plaats: AsPlaats) {
    if (this.tdrRegel) {
      var index;
      if (this.plaats.aankomstVertrek) {
        index = this.trein.regels.findIndex(regel => regel.plaats == this.plaats.naam && regel.trajectid == this.traject.id && regel.aard == this.plaats.aard);
      }
      else {
        index = this.trein.regels.findIndex(regel => regel.plaats == this.plaats.naam && regel.trajectid == this.traject.id);
      }
      if (index > -1) {
        this.trein.regels.splice(index, 1);
        this.tdrRegel = null;
        this.notify.emit();
      }
    }
    else {
      let tijd:number = 1;
      for (let regel of this.trein.regels) {
        tijd = regel.tijd;
      }
      let tdrRegel = new TdrRegel(traject.id, plaats.naam, tijd);
      if (this.plaats.aankomstVertrek) {
        tdrRegel.aard = this.plaats.aard;
      }
      this.trein.regels.push(tdrRegel);
      this.setTdrRegel();
      this.notify.emit();
    }
  }

  incrementTime(regel:TdrRegel, increment) {
    regel.tijd += increment;
    this.notify.emit({"regel": regel, "increment": increment});
  }
}
