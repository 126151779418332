import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Trein } from "./trein";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class TreinService {

  private url = './assets/treinen.json';  // URL to web api

  constructor(private http: HttpClient) { }

  getTreinen (): Observable<Trein[]> {
    return this.http.get<Trein[]>(this.url);
  }
}
