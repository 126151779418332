import {Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import {Trein} from "./trein";
import {Traject} from "../traject/traject";
import {TdrRegel} from "../tdr-regel/tdr-regel.model";
import {TdrRegelAard} from "../tdr-regel/tdr-regel-aard.enum";

@Component({
  selector: 'trein',
  templateUrl: './trein.component.html',
  styleUrls: ['./trein.component.css'],
})
export class TreinComponent {
  tdrRegelAard = TdrRegelAard;
  @Input() trajecten: Traject[];
  @Input() trein: Trein;
  @Input() loks: string[];
  @Input() opmerkingen: string[];
  @Input() trajectenTonen: number[];

  @Output()
  notify: EventEmitter<null> = new EventEmitter<null>();

  @Output()
  copyTreinEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  removeTreinEvent: EventEmitter<any> = new EventEmitter<any>();

  incrementTimes(trein: Trein, increment: number) {
    for (let regel of trein.regels) {
      regel.tijd += increment;
    }
    this.onNotify(null);
  }

	incrementLineWidth(increment: number) {
		this.trein.lijnDikte += increment;
	}
	
  copyTrein(trein: Trein) {
    this.copyTreinEvent.emit(trein);
  }

  removeTrein(trein: Trein) {
    this.removeTreinEvent.emit(trein);
  }

  onNotify(input):void {
		if (input) {
      for (let regel of this.trein.regels) {
				if (regel.tijd > input.regel.tijd) {
          regel.tijd += input.increment;
				}
      }
		}
    this.trein.setTreinInfo();
    this.notify.emit();
  }
}
