import {TdrRegel} from "../tdr-regel/tdr-regel.model";
import {Type} from "class-transformer";

export class Trein {
  nummer: string;
  treinInfo: string;
  lijnDikte: number = 1;
  @Type(() => TdrRegel)
  regels: TdrRegel[];
  loksVoor: string[] = [];
  loksAchter: string[] = [];
  up: boolean = true;
  opmerkingen: string[] = [];

  constructor(nummer: string = "", regels: TdrRegel[] = []) {
    this.nummer = nummer;
    this.regels = regels;
  }

  setTreinInfo(): void {
    let treinInfo: string;
    if (this.nummer) {
      treinInfo = this.nummer;
    }
    else {
      if (this.regels[0]) {
        let tdrRegel: TdrRegel = this.regels[0];
        treinInfo = tdrRegel.getTijdHmm() + tdrRegel.plaats[0];
      }
    }
    if (this.loksVoor.length > 0 || this.loksAchter.length > 0) {
      treinInfo = `(${treinInfo})`;
      if (this.loksVoor.length > 0) {
        treinInfo = this.loksVoor + ' ' + treinInfo;
      }
      if (this.loksAchter.length > 0) {
        treinInfo += ' ' + this.loksAchter;
      }
    }

    this.treinInfo = treinInfo;
  }
}