import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Traject } from './traject';
import {map} from "rxjs/operators";
import {AsPlaats} from "./as-plaats";
import {TdrRegelAard} from "../tdr-regel/tdr-regel-aard.enum";

@Injectable()
export class TrajectService {

  private url = './assets/diagram.json';  // URL to web api

  constructor(
    private http: HttpClient) { }

  getTrajecten (): Observable<Traject[]> {
    return this.http.get<any>(this.url).pipe(
      map(diagram => diagram.trajecten),
      map(trajecten => this.addAankomstVertrekAsPlaatsen(trajecten))
    );
  }

  private addAankomstVertrekAsPlaatsen(trajecten) {
    trajecten.forEach(traject => {
      let asPlaatsenNieuw: AsPlaats[] = [];
      traject.asPlaatsen.forEach(asPlaats => {
        if (asPlaats.aankomstVertrek) {
          let aankomst = asPlaats;
          let vertrek = {...aankomst};
          aankomst.aard = TdrRegelAard.AANKOMST;
          vertrek.aard = TdrRegelAard.VERTREK;
          asPlaatsenNieuw.push(aankomst);
          asPlaatsenNieuw.push(vertrek);
        }
        else {
          asPlaatsenNieuw.push(asPlaats);
        }
      });
      traject.asPlaatsen = asPlaatsenNieuw;
    });
    return trajecten;
  }
}
