import { Component, OnInit } from '@angular/core';

import { LocalStorageService } from "angular-2-local-storage";
import { plainToClass } from "class-transformer";

import { Trein } from "./trein/trein";
import { TreinService } from "./trein/trein.service";
import { OpmerkingService } from './opmerking/opmerking.service';
import { Traject } from "./traject/traject";
import { TrajectService } from "./traject/traject.service";
import { LokService } from "./lok/lok.service";
import { ClipboardService } from 'ngx-clipboard'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  private localStorageService: LocalStorageService;
  trajecten: Traject[] = [];
  loks: string[];
  treinen: Trein[];
  opmerkingen: string[] = [];
  opmerkingenTonen: string[];
  treinSelected: Trein;
  trajectenTonen: number[] = [];

  constructor(
      localStorageService: LocalStorageService,
      private opmerkingService: OpmerkingService,
      private trajectService: TrajectService,
      private treinService: TreinService,
      private lokService: LokService,
      private clipboardService: ClipboardService) {
    this.localStorageService = localStorageService;
    this.treinen = localStorageService.get("treinen") as Trein[];
    if (!this.treinen) {
      this.treinen = [
        new Trein("2P12")
      ]
    }
    else {
      this.treinen = plainToClass(Trein, this.treinen) as Trein[];
    }

    this.treinSelected = this.treinen[0];
  }

  ngOnInit() {
    this.getOpmerkingen();
    this.getTrajecten();
    this.getLoks();
  }

  getOpmerkingen(): void {
    this.opmerkingService.getOpmerkingen()
      .subscribe(opmerkingen => {
        for (let opmerking of opmerkingen) {
          this.opmerkingen.push(opmerking.kort);
        }
        this.opmerkingenTonen = this.opmerkingen.slice();
      });
  }

  getTrajecten(): void {
    this.trajectService.getTrajecten()
      .subscribe(trajecten => {
        this.trajecten = trajecten;
        trajecten.forEach(traject => this.trajectenTonen.push(traject.id));
      });
  }

  getTreinen(): void {
    if (confirm("Treinen laden?")) {
      this.treinService.getTreinen()
        .subscribe(treinen => {
          this.treinen = plainToClass(Trein, treinen) as Trein[];
          this.localStorageService.set("treinen", this.treinen);
          this.treinSelected = this.treinen[0];
        });
    }
  }

  getLoks(): void {
    this.lokService.getLoks()
      .subscribe(loks => this.loks = loks);
  }

  setTreinSelected(trein: Trein) {
    this.treinSelected = trein;
  }

  onNotify():void {
    this.localStorageService.set("treinen", this.treinen);
  }

  copyTrein(trein: Trein) {
    let copy: Trein = this.deepCopy(trein);
    copy.loksVoor = [];
    copy.loksAchter = [];
    this.treinen.push(copy);
    this.localStorageService.set("treinen", this.treinen);
    this.treinSelected = copy;
  }

  removeTrein(trein: Trein) {
    var index = this.treinen.indexOf(trein, 0);
    if (index > -1) {
        this.treinen.splice(index, 1);
    }
    this.localStorageService.set("treinen", this.treinen);
    this.treinSelected = this.treinen[0];
  }
  
  treinTonen(trein: Trein, opmerkingenTonen: string[]): boolean {
    if (trein.opmerkingen.length == 0) {
      return true;
    }
    for (let opmerking of opmerkingenTonen) {
      if (trein.opmerkingen.indexOf(opmerking) > -1) {
        return true;
      }
    }
    return false;
  }

  public deepCopy(oldObj: any) {
    var newObj = oldObj;
    if (oldObj && typeof oldObj === "object") {
      newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
      for (var i in oldObj) {
        newObj[i] = this.deepCopy(oldObj[i]);
      }
    }
    return newObj;
  }

  copy(){
    this.sortTreinenTime();
    const text:string = JSON.stringify(this.treinen, null, 1);
    this.clipboardService.copyFromContent(text);
    alert("Treinen copied to clipboard");
  }

  sortTreinenTime() {
    for (let trein of this.treinen) {
      let m = new Map<number, number>();
      for (const regel of trein.regels) {
        if (m.has(regel.trajectid)) {
          if (m.get(regel.trajectid) > regel.tijd) {
            m.set(regel.trajectid, regel.tijd);
          }
        }
        else {
          m.set(regel.trajectid, regel.tijd);
        }
      }
      m = new Map([...m].sort((a, b) => a[1] === b[1] ? 0 : a[1] > b[1] ? 1 : -1))
      let offset = 2000;
      for (let key of m.keys()) {
        m.set(key, offset);
        offset += 2000;
      }
      trein.regels = trein.regels.sort((n1, n2) => m.get(n1.trajectid) + n1.tijd- m.get(n2.trajectid) - n2.tijd);
    }
  }
}
