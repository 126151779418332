import {Pipe} from "@angular/core";

@Pipe({name: "sortBy"})
export class SortPipe {
  transform(array: Array<any>, args: string, direction: boolean): Array<any> {
    array.sort((a: any, b: any) => {
      let order = 0;
      if (a[args] < b[args]) {
        order = -1;
      }
      else if (a[args] > b[args]) {
        order = 1;
      }
      return order * (direction ? 1 : -1);
    });
    return array;
  }
}