import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';

@Injectable()
export class LokService {

  private url = './assets/loks.json';  // URL to web api

  constructor(
    private http: HttpClient) { }

  getLoks (): Observable<string[]> {
    return this.http.get<string[]>(this.url);
  }

}
