import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'minutesToTime'})
export class MinutesToTime implements PipeTransform {
  transform(time: number): string {
    if (time) {
      let minutes = time % 60;
      let hours = (time - minutes) / 60;
      let extraZero = (minutes < 10 ? "0" : "");
      return `${hours}:${extraZero}${minutes}`;
    }
    else {
      return "";
    }
  }
}